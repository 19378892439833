/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * THIS FILE IS FOR HELPER FUNCTIONS THAT DO NOT REQUIRE ADDITIONAL IMPORTS
 * SO THAT THEY CAN BE USED WITHOUT TRIGGERING ADDITIONAL IMPORTS
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

export function setSearchTerms(terms: string, instanceId: string) {
    const searchParams = getCurrentURLSearchParamsFromWindow();
    searchParams.set(instanceId + "_q", terms);
    const hash = getCurrentURLHashFromWindow();
    historyReplaceState("?" + searchParams.toString() + hash);
}

export function getCurrentURLSearchParamsFromWindow() {
    const url = new URL(window.document.location.href);
    return url.searchParams;
}

export function getCurrentURLHashFromWindow() {
    const url = new URL(document.location.href);
    return url.hash;
}

export function parseSearchTerms(
    searchParams: URLSearchParams,
    instanceId: string,
): string {
    return searchParams.get(instanceId + "_q") || "";
}

export function parseActiveGroup(
    searchParams: URLSearchParams,
    instanceId: string,
): string {
    return searchParams.get(instanceId + "_id") || "";
}

export function isSearchActive(
    searchParams: URLSearchParams,
    instanceId: string,
) {
    return searchParams.has(instanceId + "_q");
}

export function getCurrentSearchTermsFromWindow(instanceId: string) {
    return parseSearchTerms(getCurrentURLSearchParamsFromWindow(), instanceId);
}

export function getCurrentGroupFromWindow(instanceId: string) {
    return parseActiveGroup(getCurrentURLSearchParamsFromWindow(), instanceId);
}

export function getIsSearchActiveFromWindow(instanceId: string) {
    return isSearchActive(getCurrentURLSearchParamsFromWindow(), instanceId);
}

/**
 * Sets hash value for group without adding entry to the history,
 * Used for opening the search UI in a group view
 */
export function setGroupIdOnWindow(id: string, instanceId: string) {
    const searchParams = getCurrentURLSearchParamsFromWindow();
    searchParams.set(instanceId + "_id", id);
    const hash = getCurrentURLHashFromWindow();
    historyReplaceState("?" + searchParams.toString() + hash);
}

export function getGroupLinkFromWindow(options: {
    id: string;
    terms: string;
    instanceId: string;
}): string {
    const params = new URL(window.document.location.href).searchParams;
    params.set(options.instanceId + "_q", options.terms);
    params.set(options.instanceId + "_id", options.id);
    return "?" + params.toString();
}

export function getGrouppedResultsLinkFromWindow(instanceId: string): string {
    const params = new URL(window.document.location.href).searchParams;
    params.delete(instanceId + "_id");
    return "?" + params.toString();
}

/**
 * Pushes the url passed to history api,
 * @param url: string
 */
export function historyPushState(url: string) {
    history.pushState({ valuSearchPush: true }, "", url);
    dispatchValuSearchHistoryEvent();
}

/**
 * Pushes the url passed to history api without creating a new entry
 * @param url: string
 */
export function historyReplaceState(url: string) {
    history.replaceState(undefined, "", url);
    dispatchValuSearchHistoryEvent();
}

export function dispatchValuSearchHistoryEvent() {
    let event;

    try {
        event = new CustomEvent("valuSearchHistoryEvent");
        dispatchEvent(event);
    } catch (error) {
        // catch IE error
        event = document.createEvent("Event");
        event.initEvent("valuSearchHistoryEvent", true, true);
        document.dispatchEvent(event);
    }
}

/**
 * Helper function for opening Valu Search.
 * Handles history api calls for you.
 * Allows optional searchTerms param, otherwise reads currentSearchTerm
 */
export function open(options: { searchTerms?: string; instanceId: string }) {
    if (!getIsSearchActiveFromWindow(options.instanceId)) {
        // push the url to history so VS can be closed with browser back
        historyPushState(window.document.location.href);
    }

    const searchTermsToBeSet =
        options.searchTerms ||
        getCurrentSearchTermsFromWindow(options.instanceId) ||
        "";
    setSearchTerms(searchTermsToBeSet, options.instanceId);
}

export function removeVSQueryParams(
    params: URLSearchParams,
    instanceId: string,
) {
    // iterating over URLSearchParams and modifying it on the fly causes weird behaviour
    const toDelete: string[] = [];
    params.forEach((value, key) => {
        if (key.startsWith(instanceId + "_")) {
            toDelete.push(key);
        }
    });

    toDelete.forEach((key) => {
        params.delete(key);
    });

    return params;
}

export function clearVSQueryParamsOnWindow(instanceId: string) {
    const dirtyParams = getCurrentURLSearchParamsFromWindow();
    const cleanParams = removeVSQueryParams(dirtyParams, instanceId);
    historyPushState("?" + cleanParams.toString());
}

export function getInnerLinkFromWindow(params: {
    searchTerms?: string;
    groupId?: string;
    instanceId: string;
}) {
    let href = getGrouppedResultsLinkFromWindow(params.instanceId);

    if (params.groupId && params.searchTerms) {
        href = getGroupLinkFromWindow({
            id: params.groupId,
            terms: params.searchTerms,
            instanceId: params.instanceId,
        });
    }

    return href;
}

export function isValuSearchOpenOnWindow() {
    return window.document.body.classList.contains("valu-search-open");
}
