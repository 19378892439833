import {
    getCurrentURLSearchParamsFromWindow,
    open,
    isSearchActive,
} from "./utils/shared-helpers";

declare const process: any;
const anyWindow = window as any;

function loadScriptOnWindow(url: string, cb: VoidFunction) {
    const s = window.document.createElement("script");
    s.src = url;
    s.type = "text/javascript";
    s.addEventListener("load", cb);
    window.document
        .getElementsByTagName("script")![0]!
        .parentNode!.appendChild(s);
}

let loaded = false;

/**
 * The given callback will be called when Valu Search UI should be activated
 * based on the input
 */
export function onShouldLoadValuSearchOnWindow(
    inputSelector: string,
    openerSelector: string,
    instanceId: string,
    onShouldLoad: VoidFunction,
) {
    const searchInputs = window.document.querySelectorAll(inputSelector);
    const searchOpeners = window.document.querySelectorAll(openerSelector);

    const cb = () => {
        if (loaded) {
            return;
        }

        loaded = true;

        if (searchInputs) {
            for (let i = 0; i < searchInputs.length; i++) {
                searchInputs[i]!.removeEventListener("focus", focusHandler);
            }
        }

        if (searchOpeners) {
            for (let i = 0; i < searchOpeners.length; i++) {
                searchOpeners[i]!.removeEventListener("click", clickHandler);
            }
        }

        onShouldLoad();
    };

    function clickHandler() {
        cb();
        open({ searchTerms: "", instanceId: instanceId });
    }

    function focusHandler() {
        cb();
    }

    // It's enough to have just button when opening Valu Search Full Screen
    if (!searchInputs && !searchOpeners) {
        throw new Error("Cannot find search input nor button for Valu Search");
    }

    for (let i = 0; i < searchOpeners.length; i++) {
        searchOpeners[i]!.addEventListener("click", clickHandler);
    }

    // Expose the input & opener for search-ui.tsx
    anyWindow.VALU_SEARCH_INPUTS = searchInputs;
    anyWindow.VALU_SEARCH_OPENERS = searchOpeners;

    // Immediately load the search-ui if we have search terms in the hash fragment
    // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
    if (isSearchActive(getCurrentURLSearchParamsFromWindow(), instanceId)) {
        cb();
        return;
    }

    for (let i = 0; i < searchInputs.length; i++) {
        const el = searchInputs[i];
        if (el instanceof HTMLInputElement) {
            // Immediately load the search-ui if we have text in search input
            if (el.value.toString().trim()) {
                cb();
                return;
            }
            // Otherwise load it lazily when user touches a search input
            el.addEventListener("focus", focusHandler);
        }
    }
}

const IS_MODERN = (() => {
    try {
        eval("async function test() {}");
        return true;
    } catch (error) {}

    return false;
})();

export function lazyLoadValuSearch(options: {
    customer: string;
    rev: string;
    inputSelector: string;
    openerSelector: string;
    instanceId?: string;
}) {
    const optionsWithInstanceId = { instanceId: "vs", ...options };

    const PORT = window.location.protocol === "https:" ? 3471 : 8080;

    const CDN_ENDPOINT =
        process.env.NODE_ENV === "production"
            ? `https://cdn.search.valu.pro/${options.customer}`
            : `//localhost:${PORT}`;

    if (IS_MODERN) {
        lazyLoadValuSearchRealOnWindow({
            ...optionsWithInstanceId,
            cdnEndpoint: CDN_ENDPOINT,
        });
    } else {
        loadScriptOnWindow(`${CDN_ENDPOINT}/polyfills.js`, () => {
            developmentConsoleLog("loaded valu search polyfills");
            lazyLoadValuSearchRealOnWindow({
                ...optionsWithInstanceId,
                cdnEndpoint: CDN_ENDPOINT,
            });
        });
    }
}

function lazyLoadValuSearchRealOnWindow(options: {
    customer: string;
    rev: string;
    inputSelector: string;
    openerSelector: string;
    cdnEndpoint: string;
    instanceId: string;
}) {
    developmentConsoleLog("Valu Search loader started " + options.rev);

    const ValuSearchTools = {
        enableStaging() {
            window.localStorage.vsurl = `https://cdn.search.valu.pro/${options.customer}/search-ui-staging.js`;
            window.location.reload();
        },
        disableStaging() {
            delete window.localStorage.vsurl;
            window.location.reload();
        },
        enableDev(url?: string) {
            const proto = window.location.protocol;
            const port = proto === "https:" ? 3471 : 8080;

            localStorage.vsurl =
                url || `${proto}//localhost:${port}/search-ui.js`;
            window.location.reload();
        },
    };

    anyWindow.ValuSearchTools = ValuSearchTools;

    onShouldLoadValuSearchOnWindow(
        options.inputSelector,
        options.openerSelector,
        options.instanceId,
        () => {
            const searchUiScript =
                process.env.NODE_ENV === "production"
                    ? `search-ui-${options.rev}.js`
                    : `search-ui.js`;

            let url = options.cdnEndpoint + "/" + searchUiScript;

            if (localStorage.vsurl) {
                // Ensure cache bust for custom scripts
                url = localStorage.vsurl + "?v" + new Date().getTime();
                developmentConsoleLog("Valu Search using custom script " + url);
            }

            loadScriptOnWindow(url, () => {
                developmentConsoleLog("loaded search-ui");
            });
        },
    );
}

function developmentConsoleLog(message: string) {
    if (process.env.NODE_ENV !== "production") {
        console.log(message);
    }
}
